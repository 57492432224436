<template>
	<div class="log-management-container flex flex-col">
		<div class="flex flex-ac flex-pj">
			<el-form :inline="true" :model="search">
				<el-form-item label="用户名:">
					<el-input class="mr20" v-model="search.loginBy" style="width: 220px;" placeholder="输入用户名"></el-input>
				</el-form-item>
				<el-form-item label="机构:">
					<el-input class="mr20" v-model="search.orgName" style="width: 220px;" placeholder="输入机构"></el-input>
				</el-form-item>
				<el-form-item label="登录平台:">
					<el-select v-model="search.platformName" style="width: 220px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="小程序" :value="'小程序'"></el-option>
						<el-option label="PC后台" :value="'PC后台'"></el-option>
					</el-select>
				</el-form-item>
				<el-button type="primary" size="small" style="float: right; marginTop: 10px;" @click="getData">查询</el-button>
				<el-form-item label="登录时间:">
					<el-date-picker
						v-model="search.time"
						type="datetimerange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
					>
					</el-date-picker>
				</el-form-item>
			</el-form>
		</div>
		<el-table class="mt20 ft14 c333 it1" style="width: 100%" height="100%" highlight-current-row stripe :data="list" key="MenuList">
			<el-table-column prop="loginBy" label="用户名">
			</el-table-column>
			<el-table-column prop="orgName" label="所属机构">
			</el-table-column>
			<el-table-column prop="platformName" label="登录平台">
			</el-table-column>
			<el-table-column prop="loginTime" label="登录时间">
			</el-table-column>
		</el-table>
		<div class="sflex-pe page-container">
			<div class="table-page-container">
				<el-pagination 
					background 
					:current-page="search.pageIndex" 
					:page-sizes="[10, 20, 30, 40]" 
					:page-size="search.pageSize" 
					layout="total, sizes, prev, pager, next, jumper"
					:total="total" 
					@size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
				>
				</el-pagination>
			 </div>
		</div>
	</div>
</template>

<script>
	import axios from '@/utils/axios';
	import './Index.less';
	import moment from 'moment';
	export default {
		name: 'LogManagement',
		data() {
			return {
				page: 1,
				pageSize: 20,
				total: 0,
				search: {
					loginBy: '',
					orgName: '',
					platformName: '',
					time: null,
					pageIndex: 1,
					pageSize: 20,
					organId: null
				},
				list: []
			}
		},
		methods: {
			getData() {
				const { time, loginBy, orgName, platformName, pageIndex, pageSize, organId } = this.search;
				let loginTime1 = '';
				let loginTime2 = '';
				if (time) {
					const [time1, time2] = time;
					loginTime1 = moment(time1).format('YYYY-MM-DD HH:mm:ss');
					loginTime2 = moment(time2).format('YYYY-MM-DD HH:mm:ss');
				}
				axios.post(`/api/loginLog/pageList`, {
					loginTime1,
					loginTime2,
					loginBy,
					orgName,
					platformName,
					pageIndex,
					organId,
					pageSize
				}).then(rs => {
					const { list, total } = rs;
					this.list = list;
					this.total = total;
				}).catch(err => {
					this.$message.error(err);
				});
			},
			handleSizeChange(val) {
				this.search.pageSize = val;
				this.getData();
			},
			handleCurrentChange(val) {
				this.search.pageIndex = val;
				this.getData();
			}
		},
		mounted() {
			this.search.organId = localStorage.getItem("organId")
			this.getData()
		}
	}
</script>

<style>
</style>
